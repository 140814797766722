import { put, takeEvery, call, select } from 'redux-saga/effects';
import AxiosInstance from '../../services/commons/Interceptor';
import servicelist from '../../services/serviceList';
import { commonActions, projectDashboardActions, projectDashboardActionTypes } from '../actions';

export function* getIterationsFromSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const responseData = yield call(AxiosInstance.get, servicelist('getProjectDashboardIterations'), {
      params: { projectNumber: payload.data.projectNumber },
    });
    yield put(
      projectDashboardActions.getIterationsSuccess({
        isSucceeded: true,
        iterations: responseData.data,
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    yield put(
      projectDashboardActions.getIterationsFaliure({
        isSucceeded: false,
        iterations: null,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}

export function* watchGetIterationsDataStatus() {
  yield takeEvery([projectDashboardActionTypes.GET_ITERATIONS], getIterationsFromSaga);
}

// Quick Links widget
export function* getProjectDashboardSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const responseData = yield call(AxiosInstance.get, servicelist('getProjectDashboard'), {
      params: payload.data,
    });

    yield put(
      projectDashboardActions.getProjectDashboardSuccess({
        isSucceeded: true,
        overview: responseData.data,
        // quickLinks: getProjectDashboardMock,
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    yield put(
      projectDashboardActions.getProjectDashboardFaliure({
        isSucceeded: false,
        iterations: null,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}

export function* getProjectDashboardStatus() {
  yield takeEvery([projectDashboardActionTypes.GET_QUICKLINKS], getProjectDashboardSaga);
}

export function* saveQuickLinksSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const responseData = yield call(AxiosInstance.post, servicelist('saveQuicklinks'), payload.data);

    yield put(
      projectDashboardActions.saveQuickLinksSuccess({
        isSucceeded: true,
      })
    );

    yield put(
      projectDashboardActions.getProjectDashboardWidgetsData({
        projectNumber: payload.data.projectNumber,
        discipline: payload.data.discipline,
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    yield put(
      projectDashboardActions.saveQuickLinksFaliure({
        isSucceeded: false,
        iterations: null,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}

export function* saveQuickLinksStatus() {
  yield takeEvery([projectDashboardActionTypes.SAVE_QUICKLINKS], saveQuickLinksSaga);
}

export function* getDropdownQuicklinksSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const responseData = yield call(AxiosInstance.get, servicelist('getDropdownQuicklinks'), {
      params: { projectNumber: payload.data.projectNumber },
    });

    yield put(
      projectDashboardActions.getDropdownQuicklinksSuccess({
        isSucceeded: true,
        quickLinksDropdown: responseData.data,
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    yield put(
      projectDashboardActions.getDropdownQuicklinksFaliure({
        isSucceeded: false,
        iterations: null,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}

export function* getDropdownQuicklinksStatus() {
  yield takeEvery([projectDashboardActionTypes.GET_DROPDOWN_QUICKLINKS], getDropdownQuicklinksSaga);
}

export function* getProjectTeamUsersSaga(payload) {
  // api call
  try {
    yield put(commonActions.showLoader(true));
    if (payload.data?.userActivityLog) {
      const responseData = yield call(AxiosInstance.get, servicelist('getProjectTeamUsers'), {
        params: { projectNumber: payload.data.projectNumber, userActivityLog: true },
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(new Blob([responseData.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `User Activity Log.xlsx`);
      document.body.appendChild(link);
      link.click();
    } else {
      const responseData = yield call(AxiosInstance.get, servicelist('getProjectTeamUsers'), {
        params: { projectNumber: payload.data },
      });
      yield put({
        type: projectDashboardActionTypes.GET_PROJECT_TEAM_USERS_SUCCESS,
        payload: responseData.data,
      });
    }
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Error Occurred',
        theme: 'alert-danger',
      })
    );
    yield put({
      type: projectDashboardActionTypes.GET_PROJECT_TEAM_USERS_FAILURE,
      payload: [],
    });
  }
  yield put(commonActions.showLoader(false));
}

export function* watchProjectTeamUsersSaga() {
  yield takeEvery([projectDashboardActionTypes.GET_PROJECT_TEAM_USERS], getProjectTeamUsersSaga);
}

export function* savePlotPlanSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    if (payload === undefined) {
      return;
    }
    // api call
    yield call(AxiosInstance.post, servicelist('savePlotPlan'), payload.data.data, {
      params: { projectNumber: payload.data.projectNumber },
    });
    yield put(
      commonActions.infoPopup({
        messageId: 'File Uploaded Successfully',
        theme: 'alert-success',
      })
    );

    // retriggering overview api to fetch latest data

    yield put(
      projectDashboardActions.getProjectDashboardWidgetsData({
        projectNumber: payload.data.projectNumber,
        discipline: payload.data.discipline,
      })
    );
  } catch (e) {
    yield put(commonActions.infoPopup({ messageId: e.message || 'Error Occurred', theme: 'alert-danger' }));
  }
  yield put(commonActions.showLoader(false));
}

export function* watchPlotPlanSaving() {
  yield takeEvery([projectDashboardActionTypes.SAVE_PLOT_PLAN], savePlotPlanSaga);
}

export function* getEngExecProjectInfoDropdownSaga(Payload) {
  // api call
  try {
    yield put(commonActions.showLoader(true));
    const responseData = yield call(AxiosInstance.get, servicelist('getEnggProjectInfoDropdown'), {
      params: { projectNumber: Payload.data.projectNumber, discipline: Payload.data.discipline },
    });
    yield put(
      projectDashboardActions.getEngExecProjectInfoDropdownSuccess({
        projectInfoDropdownData: responseData.data,
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Error Occurred',
        theme: 'alert-danger',
      })
    );
    yield put(
      projectDashboardActions.getEngExecProjectInfoDropdownFaliure({
        projectInfoDropdownData: null,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}

export function* getEngExecProjectInfoDropdownStatus() {
  yield takeEvery([projectDashboardActionTypes.GET_ENGEXEC_PROJECT_INFO_DROPDOWN], getEngExecProjectInfoDropdownSaga);
}

export function* getEngExecProjectInfoFormSaga(payload) {
  // api call
  try {
    yield put(commonActions.showLoader(true));
    const responseData = yield call(AxiosInstance.get, servicelist('enggProjectInfoForm'), { params: payload.data });
    yield put(
      projectDashboardActions.getEngExecProjectInfoFormSuccess({
        projectInfoForm: responseData.data,
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Error Occurred',
        theme: 'alert-danger',
      })
    );
    yield put(
      projectDashboardActions.getEngExecProjectInfoFormFaliure({
        projectInfoForm: null,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}

export function* getEngExecProjectInfoFormStatus() {
  yield takeEvery([projectDashboardActionTypes.GET_ENGEXEC_PROJECT_INFO_FORM], getEngExecProjectInfoFormSaga);
}

export function* saveEngExecProjectInfoFormSaga(payload) {
  // api call
  try {
    yield put(commonActions.showLoader(true));
    const payloadData = payload.data.body ? payload.data.body : {};
    const responseData = payload.data.id
      ? yield call(AxiosInstance.put, servicelist('enggProjectInfoForm'), {
          ...payloadData,
          id: payload.data.id,
        })
      : yield call(AxiosInstance.post, servicelist('enggProjectInfoForm'), payloadData);
    yield put(commonActions.infoPopup({ messageId: 'Your form has been saved' }));
    yield put(
      projectDashboardActions.getEngExecProjectInfoForm({
        projectNumber: payloadData.projectNumber,
        submenuId: payloadData.submenuId,
        discipline: payloadData.discipline,
      })
    );
    yield call(payload.data.closeEdit, false); // closing the edit after succesful save call
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId:
          e?.response?.status === 409 ? e?.response?.data?.msg : 'Something went wrong. A system error has occurred.',
        theme: e?.response?.status === 409 ? 'alert-warning' : 'alert-danger',
      })
    );
    yield put(
      projectDashboardActions.saveEngExecProjectInfoFormFaliure({
        // projectInfoForm: null,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}

export function* saveEngExecProjectInfoFormStatus() {
  yield takeEvery([projectDashboardActionTypes.SAVE_ENGEXEC_PROJECT_INFO_FORM], saveEngExecProjectInfoFormSaga);
}

export function* getEngExecWbsInfoDropdownSaga() {
  // api call
  try {
    yield put(commonActions.showLoader(true));
    const responseData = yield call(AxiosInstance.get, servicelist('getEnggWbsInfoDropdown'));
    yield put(
      projectDashboardActions.getEngExecWbsInfoDropdownSuccess({
        wbsInfoDropdownData: responseData.data,
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Error Occurred',
        theme: 'alert-danger',
      })
    );
    yield put(
      projectDashboardActions.getEngExecWbsInfoDropdownFaliure({
        wbsInfoDropdownData: null,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}

export function* getEngExecWbsInfoDropdownStatus() {
  yield takeEvery([projectDashboardActionTypes.GET_ENGEXEC_WBS_INFO_DROPDOWN], getEngExecWbsInfoDropdownSaga);
}

export function* getEngExecWbsInfoTableSaga(payload) {
  // api call
  try {
    yield put(commonActions.showLoader(true));
    const responseData = yield call(AxiosInstance.get, servicelist('enggWbsInfoTable'), { params: payload.data });
    yield put(
      projectDashboardActions.getEngExecWbsInfoTableSuccess({
        wbsTableData: responseData.data,
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Error Occurred',
        theme: 'alert-danger',
      })
    );
    yield put(
      projectDashboardActions.getEngExecWbsInfoTableFaliure({
        wbsTableData: null,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}

export function* getEngExecWbsInfoTableStatus() {
  yield takeEvery([projectDashboardActionTypes.GET_ENGEXEC_WBS_INFO_TABLE], getEngExecWbsInfoTableSaga);
}

export function* saveEngExecWbsInfoTableSaga(payload) {
  const payloadData = payload.data.body ? payload.data.body : {};
  // api call
  try {
    yield put(commonActions.showLoader(true));
    const responseData = payload.data.id
      ? yield call(AxiosInstance.put, servicelist('enggWbsInfoTable'), {
          ...payloadData,
          id: payload.data.id,
        })
      : yield call(AxiosInstance.post, servicelist('enggWbsInfoTable'), payloadData);
    yield put(
      projectDashboardActions.getEngExecWbsInfoTable({
        projectNumber: payloadData.projectNumber,
        submenuId: payloadData.submenuId,
        discipline: payloadData.discipline,
      })
    );
    yield call(payload.data.closeEdit, false); // closing the edit after succesful save call
    yield put(commonActions.infoPopup({ messageId: 'Your form has been saved' }));
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId:
          e?.response?.status === 409 ? e?.response?.data?.msg : 'Something went wrong. A system error has occurred.',
        theme: e?.response?.status === 409 ? 'alert-warning' : 'alert-danger',
      })
    );
  }
  yield put(commonActions.showLoader(false));
}

export function* saveEngExecWbsInfoTableStatus() {
  yield takeEvery([projectDashboardActionTypes.SAVE_ENGEXEC_WBS_INFO_TABLE], saveEngExecWbsInfoTableSaga);
}

export function* getProjectDashboardWidgetsSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    if (payload === undefined) {
      return;
    }
    const responseData = yield call(AxiosInstance.get, servicelist('projectDashboardOverView'), {
      params: payload.data,
    });
    yield put({
      type: projectDashboardActionTypes.GET_PROJECT_DASHBOARD_WIDGETS_DATA_SUCCESS,
      payload: responseData.data,
    });
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Error Occurred',
        theme: 'alert-danger',
      })
    );
  }
  yield put(commonActions.showLoader(false));
}

export function* watchProjectDashboardWidgetsData() {
  yield takeEvery([projectDashboardActionTypes.GET_PROJECT_DASHBOARD_WIDGETS_DATA], getProjectDashboardWidgetsSaga);
}
