import addField from '../../assets/common/add.svg';
import addFieldColorGray from '../../assets/common/addGray.svg';
import completed from '../../assets/common/completed.svg';
import checkCircle from '../../assets/common/checkCircle.svg';
import checkCircleWhite from '../../assets/common/checkCircleWhite.svg';
import checkCircleGreen from '../../assets/common/checkCircleGreen.svg';
import emptyCircle from '../../assets/common/emptyCircle.svg';
import logo from '../../assets/common/logo.png';
import cornerArc from '../../assets/common/cornerArc.png';
import sortArrows from '../../assets/common/sortArrows.svg';
import sortUp from '../../assets/common/sortUp.svg';
import sortDown from '../../assets/common/sortDown.svg';
import trash from '../../assets/common/trash.svg';
import trashDisabled from '../../assets/common/trash-grey.svg';
import xCircleFill from '../../assets/common/xCircleFill.svg';
import errorTriangle from '../../assets/common/errorTriangle.svg';
import edit from '../../assets/common/edit.svg';
import copyClipboard from '../../assets/common/copyClipboard.svg';
import search from '../../assets/common/search.svg';
import arrowsFullscreen from '../../assets/common/arrowsFullscreen.svg';
import info from '../../assets/common/icon-info.svg';
import pencil from '../../assets/common/icon-pencil.svg';
import pencilBlue from '../../assets/common/icon-pencil-blue.svg';
import pencilDisabled from '../../assets/common/icon-pencil-grey.svg';
import pipes from '../../assets/common/pipes.png';
import question from '../../assets/common/icon-question.svg';
import bell from '../../assets/common/icon-bell.svg';
import usericon from '../../assets/common/man.png';
import downicon from '../../assets/common/caretDown.svg';
import alertOctagon from '../../assets/common/alertOctagon.svg';
import checkCompleted from '../../assets/common/icon-completed@2x.png';
import enGigwordMark from '../../assets/common/ENGigworkmark-01.png';
// import enGigLaminar from '../../assets/common/blackVeatchLaminarLogo.png';
import enGigLaminarLogo from '../../assets/common/bv-engig-logo2.svg';

import trangle from '../../assets/common/icon-alert-triangle-fill.svg';
import yellowTriangle from '../../assets/common/icon-alert-triangle-yellow-fill.svg';
import blueTriangle from '../../assets/common/icon-alert-triangle-blue-fill.svg';
import iconCheck from '../../assets/common/icon-check.svg';
import filterIcon from '../../assets/common/icon-filter.svg';
import gigOverview from '../../assets/common/gigOverview.svg';
import gigInfo from '../../assets/common/gigInfo.svg';
import gigDiscussion from '../../assets/common/gigDiscussion.svg';
import gigReferences from '../../assets/common/gigReferences.svg';
import wbsInfo from '../../assets/common/combined-shape.svg';
import projectTeam from '../../assets/common/icon-people.svg';
import reports from '../../assets/common/noun-report-3095971.svg';
import actionItems from '../../assets/common/noun-action-320248.svg';
import flagIconGreen from '../../assets/common/icon-flag-green.svg';
import flagIconBlue from '../../assets/common/icon-flag-blue.svg';
import flagIconRed from '../../assets/common/icon-flag-red.svg';
import flagIconYellow from '../../assets/common/icon-flag-yellow.svg';
import triangleTrill from '../../assets/common/icon-triangle-fill.svg';
import chevronRight from '../../assets/common/icon-chevron-right.svg';
import chevronLeft from '../../assets/common/icon-chevron-left.svg';
import leftIcon from '../../assets/common/left-icon.svg';
import leftIconDark from '../../assets/common/left-icon-blue.svg';
import repeatArrow from '../../assets/common/repeatArrow.svg';
import repeatArrowBlue from '../../assets/common/repeatArrowBlue.svg';
import iconplay from '../../assets/common/icon-play.svg';
import iconPlayGrey from '../../assets/common/icon-play-grey.svg';
import iconPause from '../../assets/common/icon-pause-fill.svg';
import iconPerson from '../../assets/common/icon-person.svg';
import iconPersonGray from '../../assets/common/icon-person-gray.svg';
import iconPlanPending from '../../assets/common/icon-plan-pending.svg';
import iconAlarm from '../../assets/common/icon-alarm.svg';
import iconMyWorkItems from '../../assets/common/icon-my-work-items.svg';
import iconGigWorkflow from '../../assets/common/icon-gigworkflow.svg';
import iconGigAssignment from '../../assets/common/icon-gigassignment.svg';
import iconDiscussion from '../../assets/common/icon-discussion.svg';
import iconAssumption from '../../assets/common/icon-assumption.svg';
import iconTransferRequest from '../../assets/common/icon-transferrequest.svg';
import iconChangeManagement from '../../assets/common/icon-changemanagement.svg';
import rightArrowIcon from '../../assets/common/right-icon.svg';
import iconCritical from '../../assets/common/icon-critical.svg';
import iconHigh from '../../assets/common/icon-high.svg';
import iconMedium from '../../assets/common/icon-medium.svg';
import iconLow from '../../assets/common/icon-low.svg';
import iconCalendar from '../../assets/common/icon-calendar.svg';
import iconForecastCalendar from '../../assets/common/icon-forecast-calendar.svg';
import iconStopwatch from '../../assets/common/icon-stopwatch.svg';
import redDotNotificationUnReadIcon from '../../assets/common/icon-header-number.svg';
import iconFragnet from '../../assets/common/icon-fragnets.svg';
import filterX from '../../assets/common/filterX.svg';
import filterGrid from '../../assets/common/filter-grid-icon.svg';
import iconColumnGutters from '../../assets/common/icon-columns-gutters.svg';
import lockedIcon from '../../assets/common/lockedIcon.svg';
import unLockedIcon from '../../assets/common/unLockedIcon.svg';
// Gig Status
import onhold from '../../assets/gigStatus/icon-on-hold.svg';
import ready from '../../assets/gigStatus/icon-ready.svg';
import inProgress from '../../assets/gigStatus/inProgress.svg';
import unknown from '../../assets/gigStatus/icon-unknown.svg';
import notReady from '../../assets/gigStatus/notReady.svg';
import iconCompleted from '../../assets/gigStatus/icon-completed.svg';
import notStarted from '../../assets/gigStatus/icon-not-started.svg';
import notStartedActive from '../../assets/gigStatus/icon-not-started-active.svg';
// Headers
import viewApprovePlan from '../../assets/header/approveProjectPlan/inactive.svg';
import viewApprovePlanActive from '../../assets/header/approveProjectPlan/active.svg';
import viewApprovePlanMuted from '../../assets/header/approveProjectPlan/muted.svg';
import leads from '../../assets/header/assignProjectLeads/inactive.svg';
import leadsActive from '../../assets/header/assignProjectLeads/active.svg';
import leadsMuted from '../../assets/header/assignProjectLeads/muted.svg';
import fragnetSteps from '../../assets/header/fragnetSteps/inactive.svg';
import fragnetStepsActive from '../../assets/header/fragnetSteps/active.svg';
import gigWorkflow from '../../assets/header/gigWorkflow/inactive.svg';
import gigWorkflowActive from '../../assets/header/gigWorkflow/active.svg';
import gigWorkflowMuted from '../../assets/header/gigWorkflow/muted.svg';
import heirarchy from '../../assets/header/hierarchy/inactive.svg';
import heirarchyActive from '../../assets/header/hierarchy/active.svg';
import manhours from '../../assets/header/importManhours/inactive.svg';
import manhoursActive from '../../assets/header/importManhours/active.svg';
import manhoursMuted from '../../assets/header/importManhours/muted.svg';
import inputOutput from '../../assets/header/inputOutput/inactive.svg';
import inputOutputActive from '../../assets/header/inputOutput/active.svg';
import inputOutputMuted from '../../assets/header/inputOutput/muted.svg';
import masterDataQAActive from '../../assets/header/masterDataQA/active.svg';
import masterDataQA from '../../assets/header/masterDataQA/inactive.svg';
import projectHome from '../../assets/header/projectHome/inactive.svg';
import projectHomeActive from '../../assets/header/projectHome/active.svg';
import library from '../../assets/header/referenceLibrary/inactive.svg';
import libraryActive from '../../assets/header/referenceLibrary/active.svg';
import libraryMuted from '../../assets/header/referenceLibrary/muted.svg';
import projectInfo from '../../assets/header/projectInfo/inactive.svg';
import projectInfoActive from '../../assets/header/projectInfo/active.svg';
import projectInfoMuted from '../../assets/header/projectInfo/muted.svg';
import wbs from '../../assets/header/wbsInfo/inactive.svg';
import wbsActive from '../../assets/header/wbsInfo/active.svg';
import wbsMuted from '../../assets/header/wbsInfo/muted.svg';
import myProjectsActive from '../../assets/header/myProjects/active.svg';
import myProjectsInActive from '../../assets/header/myProjects/inactive.svg';
import nonLaminar from '../../assets/header/nonLaminarOutputs/inactive.svg';
import nonLaminarActive from '../../assets/header/nonLaminarOutputs/active.svg';
import nonLaminarMuted from '../../assets/header/nonLaminarOutputs/muted.svg';
import rules from '../../assets/header/workflowRules/inactive.svg';
import rulesActive from '../../assets/header/workflowRules/active.svg';
import rulesMuted from '../../assets/header/workflowRules/muted.svg';
import projActivity from '../../assets/header/projectActivities/inactive.svg';
import projActivityActive from '../../assets/header/projectActivities/active.svg';
import projActivityMuted from '../../assets/header/projectActivities/muted.svg';
import p6send from '../../assets/header/p6/inactive.svg';
import p6sendActive from '../../assets/header/p6/active.svg';
import p6Muted from '../../assets/header/p6/muted.svg';
import laminarScopeActive from '../../assets/header/laminarScope/active.svg';
import laminarScopeInActive from '../../assets/header/laminarScope/inactive.svg';
import changeManagement from '../../assets/header/changeManagement/inactive.svg';
import changeManagementActive from '../../assets/header/changeManagement/active.svg';
import changeManagementMuted from '../../assets/header/changeManagement/muted.svg';
import projectDashboard from '../../assets/header/projectDashboard/inactive.svg';
import projectDashboardActive from '../../assets/header/projectDashboard/active.svg';
import iconChart from '../../assets/common/icon-chart.svg';
import iconLogicties from '../../assets/common/icon-logicties.svg';
import iconNounArrow from '../../assets/common/noun-arrow.svg';
import iconStopProcess from '../../assets/common/stop-process.svg';

const ICONS = {
  ADD_FIELD: addField,
  ADD_FIELD_GRAY: addFieldColorGray,
  COMPLETED: completed,
  CHECK_CIRCLE: checkCircle,
  CHECK_CIRCLE_WHITE: checkCircleWhite,
  CHECK_CIRCLE_GREEN: checkCircleGreen,
  EMPTY_CIRCLE: emptyCircle,
  DEFAULT: projectInfo,
  DEFAULT_ACTIVE: projectInfoActive,
  SORT_ARROWS: sortArrows,
  SORT_UP: sortUp,
  SORT_DOWN: sortDown,
  TRASH: trash,
  TRASH_DISABLED: trashDisabled,
  TRIANGLE_TRILL: triangleTrill,
  CHEVRON_LEFT: chevronLeft,
  CHEVRON_RIGHT: chevronRight,
  CLOSEX: xCircleFill,
  ERROR_TRIANGLE: errorTriangle,
  EDIT: edit,
  COPY_CLIPBOARD: copyClipboard,
  SEARCH: search,
  ARROWS_FULLSCREEN: arrowsFullscreen,
  INFO: info,
  PENCIL: pencil,
  PENCIL_BLUE: pencilBlue,
  PENCIL_DISABLED: pencilDisabled,
  QUESTION: question,
  BELL: bell,
  DOWN_ICON: downicon,
  ALERT_OCTAGON: alertOctagon,
  ICON_COMPLETED: iconCompleted,
  ON_HOLD: onhold,
  IN_PROGRESS: inProgress,
  READY: ready,
  NOT_READY: notReady,
  UNKNOWN: unknown,
  NOT_STARTED: notStarted,
  NOT_STARTED_ACTIVE: notStartedActive,
  TRANGLE: trangle,
  YELLOW_TRIANGLE: yellowTriangle,
  BLUE_TRIANGLE: blueTriangle,
  ICON_CHECK: iconCheck,
  FILTER_ICON: filterIcon,
  GIG_OVERVIEW: gigOverview,
  GIG_INFO: gigInfo,
  GIG_DISCUSSION: gigDiscussion,
  GIG_REFERENCES: gigReferences,
  WBS_INFO: wbsInfo,
  PROJECT_TEAM: projectTeam,
  REPORTS: reports,
  ACTION_ITEMS: actionItems,
  FLAG_GREEN: flagIconGreen,
  FLAG_BLUE: flagIconBlue,
  FLAG_RED: flagIconRed,
  FLAG_YELLOW: flagIconYellow,
  LEFT_NAV_ICON: leftIcon,
  REPEAT_ARROW: repeatArrow,
  REPEAT_ARROW_BLUE: repeatArrowBlue,
  LEFT_NAV_ICON_DARK: leftIconDark,
  ICON_PLAY: iconplay,
  ICON_PLAY_GREY: iconPlayGrey,
  ICON_PAUSE: iconPause,
  ICON_PERSON: iconPerson,
  ICON_PERSON_GRAY: iconPersonGray,
  ICON_PLAN_PENDING: iconPlanPending,
  ICON_GIG_WORKFLOW: iconGigWorkflow,
  ICON_WORK_ITEMS: iconMyWorkItems,
  ICON_ALARM: iconAlarm,
  ICON_GIG_ASSIGNMENT: iconGigAssignment,
  ICON_DISCUSSION: iconDiscussion,
  ICON_ASSUMPTION: iconAssumption,
  ICON_PAUSE_PROCESS: iconStopProcess,
  ICON_TRANSFER_REQUEST: iconTransferRequest,
  ICON_CHANGE_MANAGEMENT: iconChangeManagement,
  ICON_ARROW_RIGHT: rightArrowIcon,
  ICON_CRITICAL: iconCritical,
  ICON_HIGH: iconHigh,
  ICON_MEDIUM: iconMedium,
  ICON_LOW: iconLow,
  ICON_CALENDAR: iconCalendar,
  ICON_FORECAST_CALENDAR: iconForecastCalendar,
  ICON_STOPWATCH: iconStopwatch,
  ICON_FRAGNET: iconFragnet,
  ICON_RED_DOT: redDotNotificationUnReadIcon,
  CANCEL_FILTER: filterX,
  COLUMN_GUTTERS: iconColumnGutters,
  ICON_CHART: iconChart,
  ICON_LOGICTIES: iconLogicties,
  ICON_NOUN_ARROW: iconNounArrow,
  LOCKED: lockedIcon,
  UNLOCKED: unLockedIcon,
  ICON_FILTER_GRID: filterGrid,
};

const IMAGES = {
  LOGO: logo,
  CORNER_ARC: cornerArc,
  PIPIES: pipes,
  USER_ICON: usericon,
  CHECK_COMPLETED: checkCompleted,
  ENGIG_LOGO: enGigwordMark,
  LAMINAR_ENGIG_LOGO: enGigLaminarLogo,
};

const TOP_MENU = {
  '76564cfa-f3ab-4c58-a545-b4f79c6ed143': {
    icon: projectHome,
    iconActive: projectHomeActive,
    url: `projectHome`,
  },
  '5fcd1674-7760-4df0-84ac-2ea780dfc3f5': {
    icon: projectInfo,
    iconActive: projectInfoActive,
    iconMuted: projectInfoMuted,
    url: `ProjectInformation`,
  },
  '08c96ee1-9722-4613-8c46-68e0e9205e94': {
    icon: wbs,
    iconActive: wbsActive,
    iconMuted: wbsMuted,
    url: `wbsInformation`,
  },
  '3514b4bd-c8d3-4c85-a5d7-211c0acfa81d': {
    icon: manhours,
    iconActive: manhoursActive,
    iconMuted: manhoursMuted,
    url: `manhourBudgets`,
  },
  'c7a3a315-aa7b-456f-b1d5-47fe5eb9625e': {
    icon: projActivity,
    iconActive: projActivityActive,
    iconMuted: projActivityMuted,
    url: `projectActivities`,
  },
  '9ca71c4a-cfbb-40d8-84ec-3c746c20ad01': {
    icon: inputOutput,
    iconActive: inputOutputActive,
    iconMuted: inputOutputMuted,
    url: `inputOutput`,
  },
  '237a56bc-ec95-41aa-a7dd-d0763d233e55': {
    icon: library,
    iconActive: libraryActive,
    iconMuted: libraryMuted,
    url: `referenceLibrary`,
  },
  'd9244c12-f11c-420b-b3d6-b71b00ef41e0': {
    icon: rules,
    iconActive: rulesActive,
    iconMuted: rulesMuted,
    url: `workflowRules`,
  },
  '7f07ff30-d409-4768-885a-f82b120ef7d9': {
    icon: leads,
    iconActive: leadsActive,
    iconMuted: leadsMuted,
    url: `assignProjectLeads`,
  },
  'b6f1f968-f912-40eb-82fe-bf06dc0a11d9': {
    icon: gigWorkflow,
    iconActive: gigWorkflowActive,
    iconMuted: gigWorkflowMuted,
    url: `gigworkflow`,
  },
  'dd7971d9-5685-46c5-8eb3-b9e8e70cc271': {
    icon: nonLaminar,
    iconActive: nonLaminarActive,
    iconMuted: nonLaminarMuted,
    url: `nonLaminarOutput`,
  },
  'e006e82a-389d-4b9f-8f32-20a07f242583': {
    icon: p6send,
    iconActive: p6sendActive,
    iconMuted: p6Muted,
    url: `p6send`,
  },
  '5c2776bf-b453-43b1-bcde-723fe466d222': {
    icon: viewApprovePlan,
    iconActive: viewApprovePlanActive,
    iconMuted: viewApprovePlanMuted,
    url: `view-approve-project-plan`,
  },
  'e75fdf90-b6eb-4bc7-87cf-02f41f4bff94': {
    icon: wbs,
    iconActive: wbsActive,
    url: `my-projects`,
  },
  '898b60e3-6b60-4c7e-bc1b-fa0a25c3aa7f': {
    icon: p6send,
    iconActive: p6sendActive,
    url: `p6-send-retrieve`,
  },
  'cc99f743-2abc-4aa8-ac27-e25be185e00e': {
    icon: projectHome,
    iconActive: projectHomeActive,
    url: `my-project-home`,
  },
  '0056c76e-ed73-4040-bc19-381ac1a9fe30': {
    icon: myProjectsInActive,
    iconActive: myProjectsActive,
    url: `project-dashboard`,
  },
  '8094b1ba-0046-46d1-a3a9-56125f259fc8': {
    icon: library,
    iconActive: libraryActive,
    iconMuted: libraryMuted,
    url: `reference-library`,
  },
  '0d968cb9-3364-4550-9ec4-206a9e62446b': {
    icon: changeManagement,
    iconActive: changeManagementActive,
    iconMuted: changeManagementMuted,
    url: `change-management`,
  },
  '7dd9975c-ad4e-4d55-a4f0-4bdef8332d80': {
    icon: inputOutput,
    iconActive: inputOutputActive,
    iconMuted: inputOutputMuted,
    url: `enggInputOutput`,
  },
  // 'cc99f743-2abc-4aa8-ac27-e25be185e00e': {
  //   icon: projectHome,
  //   iconActive: projectHomeActive,
  //   url: 'enggProjectHome',
  // },
  '98a2158a-15b5-4276-bbea-df1b2cbda5c9': {
    icon: masterDataQA,
    iconActive: masterDataQAActive,
    url: `masterDataQA`,
  },
  '1f57cf8b-7db3-4ff7-9a71-e542a70a35df': {
    icon: heirarchy,
    iconActive: heirarchyActive,
    url: `heirarchy`,
  },
  '68358a54-94d5-477b-ad2e-7aad85ad1ba1': {
    icon: projActivity,
    iconActive: projActivityActive,
    url: `activities`,
  },
  '131d9acf-97d0-4792-b29e-98dd570e76d9': {
    icon: fragnetSteps,
    iconActive: fragnetStepsActive,
    url: `fragnetSteps`,
  },
  '9c8fe680-9ce2-47ee-803f-b557bd2ecb25': {
    icon: gigWorkflow,
    iconActive: gigWorkflowActive,
    url: `gigs`,
  },
  '0fcdb024-7ee8-47a2-b6d0-6c00d4195c6a': {
    icon: inputOutput,
    iconActive: inputOutputActive,
    url: 'inputOutputs',
  },
  'b0685ca3-c945-48f6-a2cc-4e4b9864b571': {
    icon: library,
    iconActive: libraryActive,
    url: 'references',
  },
  '6de854e7-22b0-47d3-b473-6e3727ea0935': {
    icon: laminarScopeInActive,
    iconActive: laminarScopeActive,
    url: `enggLaminarScope`,
  },
};

export { ICONS, IMAGES, TOP_MENU };
